@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  max-height: 100px;
  border-radius: 5px;
  background-color: #495554;
}

*::-webkit-scrollbar-track {
  background: #0e1818;
}

*::-webkit-scrollbar-corner {
  background: #0e1818;
}

.onfido-sdk-ui-Theme-portal {
  position: fixed;
  z-index: 10;
}

.onfido-sdk-ui-Theme-portal svg g {
  fill: #6df1b2 !important;
}

.onfido-sdk-ui-Theme-portal svg g circle {
  fill: #6df1b2 !important;
}

.onfido-sdk-ui-Complete-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.onfido-sdk-ui-Theme-portal .onfido-sdk-ui-Theme-actionsContainer {
  padding: 0;
}

.onfido-sdk-ui-Theme-portal .ods-button {
  padding: 20px 40px;
  width: 100%;
}

.onfido-sdk-ui-crossDevice-CrossDeviceLink-numberInputSection {
  gap: 10px;
}

.onfido-sdk-ui-crossDevice-CrossDeviceLink-numberInputSection button {
  height: 100%;
  width: fit-content !important;
  font-size: 12px;
  line-height: 16px;
  padding: 0 5px !important;
}

form[aria-labelledby="phoneNumberInput"] {
  background-color: #6df1b2;
}

form[aria-labelledby="phoneNumberInput"] input {
  color: #223b39;
  background-color: #6df1b2;
}

form[aria-labelledby="phoneNumberInput"] option {
  background-color: #182a29;
  color: #eef2f2;
}

form[aria-labelledby="phoneNumberInput"] button {
  height: 100%;
}
